<template>
  <div class="cancel content">
    <h1>
      Are you sure you want to cancel?
    </h1>

    <!-- LOADING -->
    <div v-if="loading">
      Loading...
    </div>

    <!-- ERROR -->
    <div v-if="error">
      Missing info to cancel on site. Please call.
    </div>

    <!-- CANCEL SUBSCRIPTION -->
    <div v-if="info.type === 'cancel_subscription'">
      You will be cancelling your subscription of {{ formatPrice(info.amount) }}.
      You will still be able to access old content.
    </div>

    <!-- CANCEL SECOND INSTALLMENT -->
    <div v-if="info.type === 'cancel_second_installments'">
      You will be cancelling your second installment of {{ formatPrice(info.amount) }}.
      You will still be able to access old content.
    </div>

    <!-- FULL REFUND -->
    <div v-if="info.type === 'full'">
      You will recieve a full refund of {{ formatPrice(info.amount) }}.
      You will still be able to access old content.
    </div>

    <!-- HALF REFUND -->
    <div v-if="info.type === 'half'">
      You will recieve a half refund of {{ formatPrice(info.amount) }}.
      You will still be able to access old content.
    </div>

    <!-- NO REFUND -->
    <div v-if="info.type === 'too_far_in'">
      You are too far in to recieve a refund. You may still cancel if
      you would like.
    </div>

    <!-- FREE -->
    <div v-if="info.type === 'free'">
      It is free, why you cancelling?
    </div>

    <div class="text-right">
      <b-button
        class="btn-danger"
        :disabled="error || loading"
        @click="cancel"
      >
        Cancel
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Cancel',
  props: {
    id: { type: [String, Number], required: true },
  },
  data() {
    return {
      info: {
        type: 'loading',
      },
      loading: true,
      error: false,
    };
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  watch: {
    id() {
      this.fetchCancelInfo();
    },
  },
  created() {
    this.fetchCancelInfo();
  },
  methods: {
    formatPrice(price) {
      return `$${price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace('.00', '')}`;
    },
    async fetchCancelInfo() {
      this.error = false;
      this.loading = true;

      const url = `${process.env.VUE_APP_API_URL}enrollments/${this.id}/cancel_info/`;

      let response;

      try {
        response = await fetch(
          url,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${this.userData.drf_token}`,
            },
          },
        );
      } catch (e) { } // eslint-disable-line no-empty

      this.loading = false;

      if (response && response.status === 200) {
        const responseJson = await response.json();
        this.info = responseJson;
        if (this.info.type === 'error') {
          this.error = true;
        }
      } else {
        this.error = true;
      }
    },
    async cancel() {
      this.error = false;
      this.loading = true;

      const url = `${process.env.VUE_APP_API_URL}enrollments/${this.id}/cancel/`;

      let response;

      try {
        response = await fetch(
          url,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${this.userData.drf_token}`,
            },
          },
        );
      } catch (e) { } // eslint-disable-line no-empty

      this.loading = false;

      if (response && response.status === 200) {
        const responseJson = await response.json();
        this.$store.commit('userData', responseJson);
        this.$router.push({ name: 'main' });
      } else {
        this.error = true;
      }
      return response;
    },
  },
};
</script>

<style lang="scss" scoped>
  .cancel {
    max-width: 600px;
  }
</style>
